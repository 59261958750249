import { FC } from "react";
import { AdaptiveCard } from "adaptivecards-react";
import ChatMessageMarkdown from "./chat-message-markdown";

const ChatMessageAdaptiveCard: FC<{ text: string; }> = ({ text }) => {
  var hostConfig = {
    fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
    fontSizes: {
      default: 16,
      small: 14,
      medium: 18,
      large: 20,
      extraLarge: 22,
    }
  };
  try {
    return <AdaptiveCard payload={JSON.parse(text)} hostConfig={hostConfig} />
  }
  catch {
    return <ChatMessageMarkdown text={text} />
  }
}

export default ChatMessageAdaptiveCard;